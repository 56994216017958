@tailwind base;
@tailwind components;
@tailwind utilities;

body {
      background-color: rgb(254 252 232);
}

.content {
      max-width: 1280px;
      margin: auto;
      padding-left: 10px;
      padding-right: 10px;
}

.content_card {
      @apply p-3 my-2 flex flex-col justify-between gap-2 border border-solid border-gray-300 rounded-2xl bg-white shadow-xl;
  }